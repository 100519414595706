import './LicencePlateReader.css';
import UploadFile from 'components/buttons/UploadFile';
function LicencePlateReader() {
  return (
    <div className='project'>
      <h1>Licence Plate Reader</h1>
      <p>This is a school project that me and a classmate made for a Mashine Learning exam.</p>
      <video className='video' width="320" height="240" autoPlay={true} loop={true} controls src='/images/mig.mp4'/>
      <h1> Try it yourself! </h1>
      <UploadFile />
    </div>
  );
}

export default LicencePlateReader;