import React from 'react'
import { useParams } from 'react-router-dom';

function Project() {
  const { id } = useParams<{ id: string }>();
  return (
    <div>
        <h1>Project Detail for Project ID: {id}</h1>

    </div>
  )
}

export default Project
