import React from 'react'
import './Header.css'
function Header() {
  return (
    <div className='header'>
        <img src='/images/mixer100.png'/>
        <h1>Portfolio</h1>
    </div>
  )
}

export default Header
