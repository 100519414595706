import './App.css';
import Header from './components/header/Header.tsx';
import ProjectGrid from './components/projectGrid/ProjectGrid.tsx';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Project from './components/project/Project.tsx';
import LicencePlateReader from './components/licencePlateReader/LicencePlateReader.tsx';
function App() {
    return (
        <div className="App">
            <Header />
            <Routes>
                <Route path="/" element={<ProjectGrid />} />
                <Route path="/project/licenceplate-reader" element={<LicencePlateReader />} />
                <Route path="/project/:id" element={<Project />} />
            </Routes>

        </div>
    );
}

export default App;
